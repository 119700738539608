<template>
  <el-card shadow="never">
    <div slot="header" class="clearfix">
      <el-form
          id="change"
          status-icon
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
      > <span style="margin: 10px">
          所属客户
        </span>
        <el-select v-model="customerId" placeholder="请选择">
          <el-option
              v-for="item in customerOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <span style="margin: 10px">
          周
        </span>
        <el-date-picker
            :picker-options="pickerOptions"
            v-model="week"
            @change="disabledEditw()"
            type="week"
            format="yyyy 第 WW 周"
            placeholder="选择周">
        </el-date-picker>
        <span style="margin: 10px">
          月
        </span>
        <el-date-picker
            style="margin-right: 10px"
            :picker-options="pickerOptions"
            v-model="month"
            @change="disabledEditm()"
            type="month"
            placeholder="选择月">
        </el-date-picker>
        <el-button
            type="primary"
            @click="cgetTypeList()"
            style="background: #1d90ff; border: none"
        >查询
        </el-button
        >

      </el-form>
    </div>
    <el-table
        stripe
        :data="tableData"
        style="width: 100%; margin-top: 10px"
        border
        @sort-change="changeTableSort"
        :default-sort="{ prop: 'amount', order: 'descending' }"
    >
      <el-table-column
          prop="keyword"
          label="关键词"
          width="180"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="visitorCountSum"
          sortable="custom"
          label="带来的访客数"
          width="140"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="viewsSum"
          sortable="custom"
          label="带来的浏览量"
          width="130"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="missRateAvg"
          sortable="custom"
          label="跳失率"
          width="115"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="buyerCountSum"
          sortable="custom"
          label="引导下单买家数"
          width="150"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="conRateAvg"
          sortable="custom"
          label="引导下单转化率"
          width="150"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="payAmountSum"
          sortable="custom"
          label="引导支付金额"
          width="130"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="payCountSum"
          sortable="custom"
          label="引导支付件数"
          align="center"
          width="130"
      >
      </el-table-column>
    </el-table>
    <div style="text-align: center;margin-top: 20px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage4"
          :page-sizes="[10, 20, 50, 60]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagetotal"
      >
      </el-pagination>
    </div>
  </el-card>
</template>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
<script>
import $ from "jquery";

export default {
  methods: {
    disabledEditw() {
      if (this.week != null) {
        this.month = null

      }


    },
    disabledEditm() {
      if (this.month != null) {
        this.week = null

      }

    },
    getCustomerList() {
      this.$api.get(
          "/customer/list",
          null,
          {
            list: false
          },
          (successRes) => {
            this.customerOptions = successRes.data.rows;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },


    cgetTypeList(page, record) {
      this.getTimes();

      this.$api.get(
          "/taobao/dh/list",
          null,
          {
            custId: this.customerId,
            startTime: this.startTime,
            endTime: this.endTime,
            visitorCount: this.visitorCount,
            views: this.views,
            missRate: this.missRate,
            buyerCount: this.buyerCount,
            conRate: this.conRate,
            payAmount: this.payAmount,
            payCount: this.payCount,

          },
          (successRes) => {

            //日期改掉关键字
            var exf = successRes.data.rows;
            for (var i = 0; i < exf.length; i++) {

              let missRateAvg = (exf[i].missRateAvg * 100).toFixed(4);
              exf[i].missRateAvg = parseFloat(missRateAvg).toFixed(2) + "%";
              let conRateAvg = (exf[i].conRateAvg * 100).toFixed(4);
              exf[i].conRateAvg = parseFloat(conRateAvg).toFixed(2) + "%";
            }

            this.tableData = exf;
            this.pagetotal = successRes.data.total;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );


    },


    getTypeList(page, record) {
      this.getTimes();
      this.$api.get(
          "/taobao/dh/list",
          null,
          {
            page: page === 1 ? 1 : this.page,
            record: record === 10 ? 10 : this.record,
            custId: this.customerId,
            startTime: this.startTime,
            endTime: this.endTime,
            visitorCount: this.visitorCountSum,
            views: this.viewsSum,
            missRate: this.missRateAvg,
            buyerCount: this.buyerCountSum,
            conRate: this.conRateAvg,
            payAmount: this.payAmountSum,
            payCount: this.payCountSum,

          },
          (successRes) => {

            //日期改掉关键字
            var exf = successRes.data.rows;
            for (var i = 0; i < exf.length; i++) {
              let missRateAvg = (exf[i].missRateAvg * 100).toFixed(4);
              exf[i].missRateAvg = parseFloat(missRateAvg).toFixed(2) + "%";
              let conRateAvg = (exf[i].conRateAvg * 100).toFixed(4);
              exf[i].conRateAvg = parseFloat(conRateAvg).toFixed(2) + "%";
            }

            this.tableData = exf;
            this.pagetotal = successRes.data.total;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );


    },
    changeTableSort(column, prop, order) {
      (this.visitorCountSum = null),
          (this.viewsSum = null),
          (this.missRateAvg = null),
          (this.buyerCountSum = null),
          (this.conRateAvg = null),
          (this.payAmountSum = null),
          (this.payCountSum = null);
      if (column.prop === "visitorCountSum") {
        if (column.order === "ascending") {
          this.visitorCountSum = true;
        } else {
          this.visitorCountSum = false;
        }
      }
      if (column.prop === "viewsSum") {
        if (column.order === "ascending") {
          this.viewsSum = true;
        } else {
          this.viewsSum = false;
        }
      }
      if (column.prop === "missRateAvg") {
        if (column.order === "ascending") {
          this.missRateAvg = true;
        } else {
          this.missRateAvg = false;
        }
      }
      if (column.prop === "conRateAvg") {
        if (column.order === "ascending") {
          this.conRateAvg = true;
        } else {
          this.conRateAvg = false;
        }
      }
      if (column.prop === "payAmountSum") {
        if (column.order === "ascending") {
          this.payAmountSum = true;
        } else {
          this.payAmountSum = false;
        }
      }
      if (column.prop === "payCountSum") {
        if (column.order === "ascending") {
          this.payCountSum = true;
        } else {
          this.payCountSum = false;
        }
      }
      if (column.prop === "buyerCountSum") {
        if (column.order === "ascending") {
          this.buyerCountSum = true;
        } else {
          this.buyerCountSum = false;
        }
      }
      this.currentPage4 = 1;
      this.getTypeList(1, 10);

      console.log(column.prop); //prop标签 => nickname
      console.log(column.order); //descending降序、ascending升序
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },

    handleClick(row) {
      console.log(row);
    },
    handleSizeChange(val) {
      this.record = val;
      this.getTypeList(this.page, this.record);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getTypeList(this.page, this.record);
    },
    getTimes() {
      if (this.week == null && this.month == null) {
        this.startTime = null
        this.endTime = null

      }
      if (this.week != null) {
        const d = new Date(this.week - 1 * 24 * 3600 * 1000)
        const resDate = d.getFullYear() + '-' + this.p((d.getMonth() + 1)) + '-' + this.p(d.getDate())
        this.startTime = resDate;
        const res = new Date(+new Date(resDate) + 6 * 24 * 3600 * 1000)
        const f = new Date(res)
        const endTime = f.getFullYear() + '-' + this.p((f.getMonth() + 1)) + '-' + this.p(f.getDate())
        this.endTime = endTime;

      }


      if (this.month != null) {
        const a = new Date(this.month)
        const resDatee = a.getFullYear() + '-' + this.p((a.getMonth() + 1)) + '-' + this.p(a.getDate())
        this.startTime = resDatee;
        let ress = '';
        if (a.getMonth() + 1 == 1 || a.getMonth() + 1 == 3 || a.getMonth() + 1 == 5 || a.getMonth() + 1 == 7 || a.getMonth() + 1 == 8 || a.getMonth() + 1 == 10 || a.getMonth() + 1 == 12) {
          ress = new Date(+new Date(resDatee) + 30 * 24 * 3600 * 1000)

        } else if (a.getMonth() + 1 == 2) {
          if (((0 == a.getFullYear() % 4) && (0 != a.getFullYear() % 100)) || (0 == a.getFullYear() % 400)) {
            ress = new Date(+new Date(resDatee) + 28 * 24 * 3600 * 1000)
          } else {
            ress = new Date(+new Date(resDatee) + 27 * 24 * 3600 * 1000)
          }

        } else {
          ress = new Date(+new Date(resDatee) + 29 * 24 * 3600 * 1000)

        }

        const b = new Date(ress)
        const eendTime = b.getFullYear() + '-' + this.p((b.getMonth() + 1)) + '-' + this.p(b.getDate())
        this.endTime = eendTime;

      }


    },
    p(s) {
      return s < 10 ? '0' + s : s
    },


  },
  data() {
    return {
      pickerOptions: {
        disabledDate: time => {
          return (
              time > new Date()
          )
        },
        firstDayOfWeek: 1,
      },
      customerOptions: [],
      customerId: null,
      week: null,
      month: null,
      page: 1,
      record: 10,
      visitorCountSum: null,
      viewsSum: null,
      missRateAvg: null,
      buyerCountSum: null,
      conRateAvg: null,
      payAmountSum: null,
      payCountSum: null,
      value: "",
      tableData: [],
      asearchdata: {},
      userInfo: {},
      updateStatus: 0,
      pagetotal: 0,
      spagetotal: 10,
      nowpagetotal: 1,
      currentPage4: 1,
      value1: null,
      value2: null,
      startTime: '',
      endTime: '',
    };
  },

  mounted() {
    this.getCustomerList();

  },
};
</script>